
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

body{
    font-family: Cairo;
}

.text-styler {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .styles-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }
  
  .style-item {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .style-item:hover {
    background-color: #e0e0e0;
  }
  
  .style-item span {
    display: block;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  button {
    padding: 5px 10px;
    cursor: pointer;
  }